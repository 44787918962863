@import url("https://fonts.googleapis.com/css?family=Roboto|Quattrocento+Sans&display=swap");
@import url('https://fonts.googleapis.com/css?family=Notable');
@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.googleapis.com/css?family=Anonymous+Pro');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  font-family: 'Inter'
}

html {
  --yellow: #FDD835;
  --blue: #70D1FE;
  --red: #F44336;
  --grey: rgb(230,230,230);
  --light-green: #7f917f;
  --dark-green: #394439;
  --baby-pink: #e8dedd;
  --light-grey: #dde0e5;
  --brown: #664229;
  --navy: #24305e;
  --blue: #add4ef;
  --lavender: #d995f6;
  height: 100%;
  --minh: 40vh;
 --color1: black;
 --color2: white;
}

#parent {
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Source Code Pro', monospace;
  font-size: 28px;
  /* color: rgba(255,255,255,.70); */
  position: relative;
}

#border {
  border-bottom: solid 3px black;
  position: absolute;
  right: -7px;
  width: 20px;
}

/* Animation */
@media (min-width: 768px) {
  #parent {
    animation: animated-text 10s steps(51,end) 1s 1 normal both;
  }
}

@media (max-width: 768px) {
  #parent {
    white-space: break-spaces;
    padding: 5%;
  }
}
@media (min-width: 768px) {
  #border {
     animation: animated-cursor 10s steps(51,end) 1s 1 normal both;
  }
}
@media (max-width: 768px) {
  #border {
    white-space: break-spaces;
  }
}
/* text animation */

@keyframes animated-text{
  from{width: 0;}
  to{width: 860px;}
}

/* cursor animations */

@keyframes animated-cursor{
  from{border-bottom-color: rgba(0,0,0,.75);}
  to{border-bottom-color: transparent;}
}

input[type="text"], input[type="email"], textarea.form-control {
  background-color: rgba(0,0,0,0);
  border-top: 0px;
  border-left: 0px;
  border-right: 0;
  border-radius: 0px;
  color: rgb(51,51,51);
}

/* About Me */

.about-container {
  background-color: rgb(230,230,230);
  overflow: overlay;
  height: 100%;
  weight: 100%;
}

.about-block {
  /* width: 100%; */
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center;
   flex-flow: row; */
  display: flex;
}

.about-sidebar {
  flex-grow: 1;
  height: 100%;
  width: 50%;
  height: inherit;
}
.about-right {
  width: 100%;
  float: right;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: left;
  padding: 3%;
}

.about-text {
}

.resume {
  margin-top: 3%;
  width: fit-content;
  padding: 2% 3% 2% 3%;
  background-color: transparent;
  border: 2px solid black;
  border-radius: 10px;
}

@media(max-width:800px) {
  .about-block {
    display: revert;
  }
  .about-sidebar {
    width: 100%;
  }
}

/* JOBS */

.jobs-container {
  padding-top: 10%;
    display: flex;
    -webkit-box-align: center;
    background-color: #383838;
    padding-bottom: 5%;
    color: #e6e6e6;
    min-height: 80vh;
}

.job-tabs {
  list-style: none;
  padding-left: 15px;
  width: 100%;
  padding-right: 3%;
}

.job-tabs li {
  /* margin-bottom: 10px; */
}

.job-list {
  position: relative;
    z-index: 3;
    width: max-content;
    list-style: none;
    width: 100%;
}

.job-button-active {
  position: relative;
  transition: var(--transition);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding: 0px 20px 2px;
  border-left: 2px solid white;
  background-color: transparent;
  color: var(--lavender);
  text-align: left;
  padding-bottom: 10px;
}
.job-button-active:focus {outline:0;}
.job-button:focus {outline:0;}

.job-button {
  position: relative;
  transition: var(--transition);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding: 0px 20px 2px;
  border-left: 2px solid var(--lavender);
  background-color: transparent;
  color: var(--grey);
  text-align: left;
  padding-bottom: 10px;
}

.job-description {
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-left: 10%;
  padding-right: 15%;
  /* background-color: #e5d3b3; */
}

.job-description h3 {
  margin-bottom: 5px;
  /* font-size: var(--fz-xxl); */
  font-weight: 500;
  color: var(--lightest-slate);
}

.job-description p {
  margin: 0px 0px 15px;
}
.job-description ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.job-description li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}
.job-description li:before {
  content: "▹";
  padding-left: 10px;
  position: absolute;
  left: 0px;
  color: var(--lavender);
}

.company {
  color: var(--lavender);
}

.job-duration {
  margin-bottom: 30px;
  color: var(--light-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
}

.scroll-bar {
  height: 100%;
}

.side-panel {
  width:60%;
  padding-top: 10px;
  padding-left: 3%;
}

.invisble-job {
  /* display: none; */
}

@media (max-width: 800px) {
  .jobs-container {
    display: revert;
  }
  .job-list {
    margin-bottom: 5%;
  }
  .side-panel {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .job-list {
    margin-right: 2%;
  }
}

/* Academic */

.academic {
  /* min-height: 80vh; */
}

.academic-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1%;
  padding-bottom: 1%;
}

.academic img {
  /* grid-column: 2; */
  width: 100%;
  /* filter: grayscale(); */
}
.academic img:hover {
  /* filter: none; */
}

.academic-container {
  /* grid-column: 1; */
  background-color: #072a40;
  padding: 3%;
  color: black;
}

.academic-overline {
  margin: 0;
}

.academic-title {
  /* color: var(--lavender); */
}

@media (max-width: 1000px) {
  .academic-grid {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .academic-grid img {
    grid-column: 1 !important;
    grid-row: 1 !important;
  }
  .academic-container {
    grid-column: 1 !important;
    grid-row: 2 !important;
    margin-bottom: 1%;
  }
}


/* Academic */

.project {
  /* margin-left: 2%; */
  min-height: 80vh;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1%;
  /* row-gap: 1%; */
  /* padding: 3%; */
  margin-bottom: 1%;
  margin-right: 1%;
  /* border-radius: 20px; */
  color: black;
}

.project-content {
  /* grid-column: 1; */
  padding: 5%;
  /* margin-bottom: 2%; */
  background-color: rgb(230,230,230);
  /* border: 2px solid #f9f7f0; */
}

.project-overline {
  margin: 0;
}

.project-title {
  /* margin: 0px 0px 20px; */
  /* color: black; */
}

.project-image {
  /* grid-column: 2; */
  max-width: 100%;
  height: 100%;
  /* display: inline-block;
  vertical-align: middle; */
  /* border: 2px solid #f9f7f0; */

}
.project-image img {
  max-width: 100%;
  height: 100%;
  vertical-align: middle;
  /* filter: grayscale(); */
}
.project-image img:hover {
  /* filter: none; */
}

@media (max-width: 1000px) {
  .project-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .project-content {
    grid-column: 1 !important;
    grid-row: 2 !important;
    /* margin-bottom: 2%; */
    margin-top: 2%;
  }
  .project-image {
    grid-column: 1 !important;
    grid-row: 1 !important;
  }
}


.research {
  background-color: #383838;
  overflow: overlay;
  height: 100%;
  weight: 100%;
  padding: 5%;
  color: white;
}
.research h1 {
  text-align: center;
  /* color: var(--lavender); */
}

.research-content {
  margin-top: 5%;
  /* background-color: #433834; */
  padding: 5%;
}


/* HOME PAGE */

.homepage {
  /* display: grid; */
  margin-top: 3%;
  background-color: #ffffff;
  /* border: 5px solid black; */
  padding: 5% 5% 5% 5%;
  /* opacity: 0.8;
  background-image:  linear-gradient(#A9A9A9 1px, transparent 1px), linear-gradient(to right, #A9A9A9 1px, #ffffff 1px);
  background-size: 70px 70px; */
  background-color: #ffffff;
  opacity: 0.8;
  background-size: 50px 20px;
  background-image:  repeating-linear-gradient(0deg, #a9a9a9, #a9a9a9 1px, #ffffff 1px, #ffffff);
}
.home-right-bg {
  /* background-color: white; */
  min-height: 60vh;
  /* width: 50%; */
  /* grid-row: 1; */
  /* padding: 5%; */
  color: black;
  /* float: right; */
  margin: 0;
  text-align: center;
}

.home-right-bg h1 {
  margin-top: 2%;
}

.home-right-bg h2 {
  margin-top: 2%;
}

.home-right-bg h3 {
  margin-top: 2%;
}

.home-right-bg p {
  margin-top: 2%;
}
.home-name-right {
  /* float: left; */
  font-size: 9vw;
  /* width: 100%; */
}





.line-1{
    border-right: 2px solid rgba(0,0,0,.75);
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    font-size: 9vw;
    width: 100%;
}


/* Animation */
.anim-typewriter{
  animation: typing 4s steps(16, end) forwards,
             blinking .25s infinite;
}
@keyframes typing{
  from{width: 0;}
  to{width: 8.8em;}
}
@keyframes blinking{
  from{border-right-color: rgba(0,0,0,1);}
  to{border-right-color: transparent;}
}
